import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncSchedule } from "../Redux/features/CommonSlice";
import { getAsyncContact } from "../Redux/features/ContactSlice";

const Schedule = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncSchedule());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAsyncContact());
  }, [dispatch]);

  const getSchedule = useSelector((state) => state.Common.schedule);
  const getContact = useSelector((state) => state.Contact.contacts);


  return (
    <div>
      <section className="jarallax no-top">
        <div className="de-gradient-edge-top"></div>
        <img src="/img/background/1.jpg" className="jarallax-img" alt="" />
        <div className="container relative z1000">
          <div className="row gx-5">
            <div
              className="col-lg-6 text-center my-3"
              data-jarallax-element="-50"
            >
              <div className="d-sch-table">
                <h2 className="">We are Open</h2>
                <div className="d-col">
                  <div className="d-title">Mon - Friday</div>
                  <div className="d-content id-color">{getSchedule?.openTime} AM</div>
                </div>
                <div className="de-separator"></div>

                <div className="d-col">
                  <div className="d-title">Sat - Sun</div>
                  <div className="d-content id-color">{getSchedule?.closeTime} PM</div>
                </div>

                <div className="d-deco"></div>
              </div>
            </div>

            <div
              className="col-lg-6 text-center my-3"
              data-jarallax-element="-100"
            >
              <div className="d-sch-table">
                <h2 className="">Location</h2>
                <div className="d-col">
                  <div className="d-title">Address</div>
                  <div className="d-content id-color">{getContact?.address}</div>
                </div>
                <div className="de-separator"></div>
                <div className="d-col">
                  <div className="d-title">Phone</div>
                  <div className="d-content id-color">{getContact?.phone}</div>
                </div>

                <div className="d-deco"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="de-gradient-edge-bottom"></div>
      </section>
    </div>
  );
};

export default Schedule;