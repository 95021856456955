import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

// about
export const getAsyncAboutDetails = createAsyncThunk(
  "about/getAboutDetails",
  async () => {
    try {
      const res = await axiosInstance.get("/barbershop/about");
      // console.log(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

// employees 
export const getAsyncEmployees = createAsyncThunk(
  "employees/getAsyncEmployees",
  async () => {
    try {
      const res = await axiosInstance.get("/barbershop/employees");
      // console.log("employee",res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

// initial state
const initialState = {
  // about data
  about: {},
  status: "idle",
  error: null,
  // employees data
  employees: [],
  employeesStatus: "idle",
  employeesError: null,
};

// About Slice
const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // employees
      .addCase(getAsyncEmployees.pending, (state) => {
        state.employeesStatus = "loading";
      })
      .addCase(getAsyncEmployees.fulfilled, (state, action) => {
        state.employeesStatus = "succeeded";
        state.employees = action.payload;
      })
      .addCase(getAsyncEmployees.rejected, (state, action) => {
        state.employeesStatus = "failed";
        state.employeesError = action.employeesError.message;
      })
      // about
      .addCase(getAsyncAboutDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAsyncAboutDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.about = action.payload;
      })
      .addCase(getAsyncAboutDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default aboutSlice.reducer;