import { useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import WaitList from "../WaitList";
import SelectService from "../SelectService";
import DateAndTime from "../DateAndTime";
import UserInfo from "../UserInfo";
import Otp from "../Otp";
import Staff from "../Staff";

const Wizard = () => {
  const [goSteps, setGoSteps] = useState(0);
  const [mode, setMode] = useState("");
  const [employeeId, setEmployeeId] = useState("");

  // Initialize state to hold IDs
  const [selections, setSelections] = useState({
    serviceId: null,
    employeeId: null,
  });
  const [dateAndTime, setDateAndTime] = useState();

  // Function to update selected service ID
  const handleServiceSelection = (id) => {
    setSelections((prev) => ({ ...prev, serviceId: id }));
  };

  // Function to update selected employee ID
  const handleEmployeeSelection = (id) => {
    setSelections((prev) => ({ ...prev, employeeId: id }));
    setEmployeeId(id);
  };

  const handleButtonClick = (data) => {
    setMode(data);
    setGoSteps(1);
  };


  // Function to catch date and time
  const handleDateTimes = (data) => {
    setDateAndTime(data);
  };

  return (
    <section id="waitlist" className="no-top jarallax">
      <img src="/img/background/1.jpg" className="jarallax-img" alt="" />
      <div className="" style={{ paddingTop: "150px" }}>
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className=""></div>
            <div className="card-body ">
              <div>
                <Stepper
                  className="container"
                  activeStep={goSteps}
                  activetextcolor="var(--primary-color)"
                >
                  <Step
                    style={{
                      backgroundColor:
                        goSteps >= 0
                          ? "var(--primary-color)"
                          : "#343541",
                    }}
                    onClick={() => setGoSteps(0)}
                  />
                  <Step
                    style={{
                      backgroundColor:
                        goSteps >= 1
                          ? "var(--primary-color)"
                          : "#343541",
                    }}
                    onClick={() => setGoSteps(1)}
                  />
                  <Step
                    style={{
                      backgroundColor:
                        goSteps >= 2
                          ? "var(--primary-color)"
                          : "#343541",
                    }}
                    onClick={() => setGoSteps(2)}
                  />
                  <Step
                    style={{
                      backgroundColor:
                        goSteps >= 3
                          ? "var(--primary-color)"
                          : "#343541",
                    }}
                    onClick={() => setGoSteps(3)}
                  />
                  <Step
                    style={{
                      backgroundColor:
                        goSteps >= 4
                          ? "var(--primary-color)"
                          : "#343541",
                    }}
                    onClick={() => setGoSteps(4)}
                  />
                  {/* <Step
                    style={{
                      backgroundColor:
                        goSteps >= 5
                          ? "var(--primary-color)"
                          : "#343541",
                    }}
                    onClick={() => setGoSteps(5)}
                  /> */}
                </Stepper>
                {goSteps === 0 && (
                  <>
                    <WaitList 
                    setGoSteps={setGoSteps} 
                    handleButtonClick={handleButtonClick}
                     />
                    <div className="text-end toolbar toolbar-bottom p-2"></div>
                  </>
                )}
                {goSteps === 1 && (
                  <>
                    <SelectService
                     setGoSteps={setGoSteps}
                     onServiceSelect={handleServiceSelection}
                      selections={selections}
                       />
                  </>
                )}
                {goSteps === 2 && (
                  <>
                    <Staff 
                    setGoSteps={setGoSteps}
                    onEmployeeSelect={handleEmployeeSelection}
                    selections={selections}
                    mode={mode}
                     />
                  </>
                )}
                {mode === "reservation" ? (
                  <>
                    {goSteps === 3 && (
                      <>
                        <DateAndTime
                          handleDateTimes={handleDateTimes}
                          employeeId={employeeId}
                          setGoSteps={setGoSteps}
                        />
                      </>
                    )}
                    {goSteps === 4 && (
                      <>
                        <UserInfo
                          setGoSteps={setGoSteps}
                          selections={selections}
                          mode={mode}
                          dateAndTime={dateAndTime}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {goSteps === 3 && (
                      <>
                        <UserInfo
                          setGoSteps={setGoSteps}
                          selections={selections}
                          mode={mode}
                          dateAndTime={dateAndTime}
                        />
                      </>
                    )}
                  </>
                )}

                {/* {goSteps === 3 && (
                  <>
                    <DateAndTime setGoSteps={setGoSteps} />
                  </>
                )}
                {goSteps === 4 && (
                  <>
                    <UserInfo setGoSteps={setGoSteps} />
                  </>
                )}   */}
                {goSteps === 5 && (
                  <>
                    <Otp setGoSteps={setGoSteps} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wizard;