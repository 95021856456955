import { useState } from "react";
import OtpInput from "react-otp-input";
import { BiRightArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

const Otp = ({ setGoSteps }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  console.log(otp);

  const waitlistId = useSelector((state) => state.Waitlist.waitlist.id);
  console.log(waitlistId);

  // Submit OTP
  const handleSubmit = () => {
    setGoSteps(6);
    // console.log({ waitlistId: waitlistId, data: { verificationCode: otp } });
    // dispatch(VerifyAsyncPhoneNumber({ waitlistId: waitlistId, data: { verificationCode: otp } }));
  };

  return (
    <div className="row justify-content-center mx-1">
      <div id="dialog" className="custom-border col-md-10 col-lg-7 col-12 waitlist-box">
        <div className="row">
          <div className="col-2 text-start">
            <AiOutlineArrowLeft
              style={{ background: "var(--primary-color)" }}
              onClick={() => setGoSteps(4)}
              className=" gj-cursor-pointer back-btn fs-1 p-1 rounded"
            ></AiOutlineArrowLeft>
          </div>
          <div className="col-8 text-center">
            <h2 className=" display-6 fw-bold ">
              
              Please enter the 4-digit OTP code
            </h2>
          </div>
          <div className="col-2 text-start"></div>
        </div>

        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          inputType={true}
          containerStyle="custom-otp-container"
          inputStyle="custom-otp-input"
          renderSeparator={
            <span className="fw-bold display-6">
              <BiRightArrowAlt className="d-none"></BiRightArrowAlt>
            </span>
          }
          renderInput={(props) => (
            <input style={{ width: "5rem" }} {...props} />
          )}
        />
        <div className="col-8 col-lg-6 mx-auto">
          <Link to="/confirmation">
            <button
              // onClick={() => setGoSteps(6)}
              onClick={handleSubmit}
              className="btn-main bg-primary mx-auto my-5 w-100 px-0"
            >
              Verify
            </button>
          </Link>
        </div>
        <div className="text-center mt-2">
          Didn't receive the code?
          <br />
          <Link
            className="gj-cursor-pointer custom-border py-0"
            style={{ color: "var(--primary-color)", backgroundColor: "white" }}
          >
            Send code again
          </Link>
          <br />
        </div>
      </div>
    </div>
  );
};

export default Otp;