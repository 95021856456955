import { useEffect } from "react";
import { jarallax } from "jarallax";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../css/colors/scheme-01.css";
import "../css/style.css";
import Schedule from "../components/Schedule";
import Services from "../components/Services";
import Gallery from "../components/Gallery";
import Wizard from "../components/WaitList/Steps/Wizard";
import MarqueeHeader from "../components/MarqueeHeader";
import Trending from "../components/Trending";
import { getAsyncSocialLinks } from "../Redux/features/CommonSlice";
import { useDispatch } from "react-redux";

function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  useEffect(() => {
    dispatch(getAsyncSocialLinks());
  }, [dispatch]);

  return (
    <div id="wrapper">
      <Header />
      <div className="no-bottom no-top" id="content">
        <div id="top"></div>
        <MarqueeHeader />
        <Wizard></Wizard>
        <Trending></Trending>
        <Services></Services>
        <Gallery></Gallery>
        <Schedule></Schedule>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
