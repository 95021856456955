import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReservationTimeSlots } from "../../Redux/features/ReservationSlice";

function DateAndTime({ handleDateTimes, setGoSteps, employeeId }) {
  const dispatch = useDispatch();
  const [dates, setDates] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const [formattedDateState, setFormattedDate] = useState("");
  const [formattedTimeState, setFormattedTime] = useState("");

  useEffect(() => {
    if (employeeId) {
      dispatch(
        getReservationTimeSlots({
          date: formattedDateState,
          employeeId: employeeId,
        })
      );
    } else {
      console.log("Employee ID not found");
    }
  }, [dispatch, employeeId, formattedDateState]);

  const submitDateAndTime = () => {
    handleDateTimes({
      reservationDate: formattedDateState,
      reservationTime: formattedTimeState,
    });
    setGoSteps(4);
  };

  // Example usage:
  useEffect(() => {
    if (selectedOption || selectedTime) {
      const date = convertSelectedOptionToISO(selectedOption);
      setFormattedDate(date);
      setFormattedTime(selectedTime);
    }
  }, [selectedOption, selectedTime]);

  const times = useSelector((state) => state.Reservation.timeSlots);
  const timeStatus = useSelector((state) => state.Reservation.status);
  console.log(times);

  useEffect(() => {
    const generateDateList = () => {
      const today = new Date();
      const twoYearsLater = new Date(today);
      twoYearsLater.setFullYear(today.getFullYear() + 2);
      const dateList = [];
      let currentDate = new Date(today);
      while (currentDate <= twoYearsLater) {
        dateList.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      setDates(dateList);
      setSelectedOption(dateList[0].toDateString());
    };
    generateDateList();
  }, []);

  // Function to convert the selectedOption to yyyy-mm-dd format
  const convertSelectedOptionToISO = (selectedDateStr) => {
    const date = new Date(selectedDateStr);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //Time format conversion

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedTime("");
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const getValidTimeOptions = () => {
    if (!selectedOption) return times;
    const now = new Date();
    const selectedDate = new Date(selectedOption);
    if (
      now.getDate() === selectedDate.getDate() &&
      now.getMonth() === selectedDate.getMonth() &&
      now.getFullYear() === selectedDate.getFullYear()
    ) {
      // The selected date is today, let's filter out times that are in the past
      const nowHours = now.getHours();
      const nowMinutes = now.getMinutes();
      return times?.filter((time) => {
        const [timeHours, timeMinutes] = time.split(":");
        return (
          parseInt(timeHours) > nowHours ||
          (parseInt(timeHours) === nowHours &&
            parseInt(timeMinutes) > nowMinutes)
        );
      });
    }
    return times;
  };

  const timeOptions = getValidTimeOptions();

  const formattedSelectedOption = new Date(selectedOption);
  const formattedDate = `${formattedSelectedOption.toLocaleDateString("en-US", {
    weekday: "long",
  })}, ${formattedSelectedOption.toLocaleDateString("en-US", {
    month: "short",
  })} ${formattedSelectedOption.getDate()}`;

  return (
    <>
      <div className="">
        <div className="row justify-content-center mx-1">
          <div className="col-md-10 col-lg-9 col-xxl-7 col-12  text-center custom-border waitlist-box">
            <div className="row">
              <div className="col-2 text-start">
                <AiOutlineArrowLeft
                  style={{ background: "var(--primary-color)" }}
                  onClick={() => setGoSteps(2)}
                  className=" gj-cursor-pointer back-btn fs-1 p-1 rounded"
                ></AiOutlineArrowLeft>
              </div>
              <div className="col-8 text-center">
                <h2 className="text-center display-6">Date and Time</h2>
              </div>
              <div className="col-2 text-start"></div>
            </div>
            <div className="d-flex position-relative pt-3 gap-4 gj-cursor-pointer">
              <AiOutlineArrowLeft className=" swiper-button-prev"></AiOutlineArrowLeft>
              <AiOutlineArrowRight className=" swiper-button-next"></AiOutlineArrowRight>
            </div>
            <div className="mt-3 mb-5">
              <Swiper
                spaceBetween={10}
                slidesPerView={7}
                modules={[Navigation]}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                breakpoints={{
                  1024: {
                    slidesPerView: 7,
                  },
                  768: {
                    slidesPerView: 4,
                  },
                  320: {
                    slidesPerView: 3,
                  },
                }}
              >
                {dates.map((date, index) => {
                  let radioID = `date_radio_${index}`;
                  return (
                    <SwiperSlide key={index}>
                      <div className="slider__date">
                        <input
                          type="radio"
                          id={radioID}
                          name="date"
                          value={date.toDateString()}
                          defaultChecked={index === 0}
                          onChange={handleOptionChange}
                        />
                        <label
                          htmlFor={radioID}
                          className="d-flex flex-column align-items-center"
                        >
                          <span>
                            {date
                              .toLocaleDateString("en-US", {
                                weekday: "short",
                              })
                              .substring(0, 3)}
                          </span>
                          <span className="fs-3 fw-bold">
                            {date.toLocaleDateString("en-US", {
                              day: "numeric",
                            })}
                          </span>
                          <span>
                            {date
                              .toLocaleDateString("en-US", {
                                month: "short",
                              })
                              .substring(0, 3)}
                          </span>
                        </label>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="mobile-view">
              <div>
                <p className="fw-bold">{formattedDate}</p>
              </div>
              {timeStatus === "loading" ? (
                <div class="d-flex justify-content-center py-5">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="time__container">
                  {selectedOption &&
                    (timeOptions?.length > 0 ? (
                      timeOptions.map((time, index) => {
                        let radioID = `time_radio_${index}`;
                        return (
                          <div className="time__wrapp" key={index}>
                            <input
                              type="radio"
                              id={radioID}
                              name="time"
                              value={time}
                              onChange={handleTimeChange}
                              checked={time === selectedTime}
                            />
                            <label htmlFor={radioID}>
                              <span>{time}</span>
                            </label>
                          </div>
                        );
                      })
                    ) : (
                      <p className="text-center">
                        There are no time slots for today.
                      </p>
                    ))}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-8 col-lg-6 mx-auto mt-5">
                <input
                  onClick={() => submitDateAndTime()}
                  type="submit"
                  id="send_message"
                  value="NEXT"
                  className="btn-main bg-primary w-100"
                />
              </div>
            </div>
          </div>

          <div className="d-deco"></div>
        </div>
      </div>
    </>
  );
}

export default DateAndTime;
