import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

function Header() {
  const dispatch = useDispatch();
  const [isScrolled, setIsScrolled] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // fetch data from api
  const socialLink = useSelector((state) => state.Common.socialLinks);

  return (
    <header
      style={
        isScrolled ? { backgroundColor: "#101010", transition: ".2s" } : {}
      }
      className="transparent py-2 header-wrapper"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="de-flex">
              <div className="de-flex-col">
                <div className="de-flex-col">
                  <div id="logo">
                    <Link to="/">
                      <img
                        className="logo-main"
                        src="/img/ABSS.webp"
                        alt=""
                        style={{ height: "70px" }}
                      />
                      <img
                        className="logo-mobile"
                        src="/img/ABSS.webp"
                        style={{ height: "50px" }}
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="de-flex-col header-col-mid">
                <ul id="mainmenu" className={openMenu ? "openMenu" : ""}>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="de-flex-col">
                <div className="menu_side_area">
                  <div className="social-icons big text-center d-none d-lg-block ">
                    <a
                      href={socialLink?.facebook}
                      target="_blank"
                      rel="noreferrer"
                      className="social-box py-2"
                    >
                      <FaFacebookF />
                    </a>
                    <a
                      href={socialLink?.instagram}
                      target="_blank"
                      rel="noreferrer"
                      className="social-box py-2"
                    >
                      <FaInstagram />
                    </a>
                    <a
                      href={socialLink?.tiktok}
                      target="_blank"
                      rel="noreferrer"
                      className="social-box py-2"
                    >
                      <FaTiktok />
                    </a>
                  </div>
                  <GiHamburgerMenu id="menu-btn" onClick={toggleMenu} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;