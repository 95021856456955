import React, { useEffect } from "react";
import { jarallax } from "jarallax";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { getTodaysWaitlist } from "../Redux/features/WaitlistSlice";
import Loading from "../components/Loading";

function WaitList() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTodaysWaitlist());
  }, [dispatch]);
  const todaysWaitlist = useSelector((state) => state.Waitlist.todaysWaitlist);
  console.log(todaysWaitlist);
  const status = useSelector((state) => state.Waitlist.status);

  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);
  return (
    <>
      <Header></Header>
      <div id="wrapper">
        <div className="no-bottom no-top" id="content">
          <div id="top"></div>

          <section id="subheader" className="jarallax">
            <img src="/img/background/2.jpg" className="jarallax-img" alt="" />
            <div className="container">
              <div className="row">
                <div className="col-lg-6 pt-5  offset-lg-3 text-center">
                  <h1>waitlist</h1>
                  <div className="de-separator"></div>
                </div>
              </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
          </section>

          <section id="wait" className="jarallax ">
            <div className="de-gradient-edge-top"></div>
            <img src="/img/background/1.jpg" className="jarallax-img" alt="" />
            <div className="relative z1000">
              <div className="row justify-content-center mx-1">
                <div
                  className="col-md-10 col-lg-9 col-xxl-5 col-11 text-center"
                  data-jarallax-element="-50"
                >
                  <div className="d-sch-table">
                    <h2>{todaysWaitlist.length} waiting</h2>
                    {/* <h4>Estimated wait -- min</h4> */}
                    <div className="de-separator"></div>
                    {status === "loading" ? (
                      <Loading />
                    ) : (
                      <>
                        {" "}
                        {todaysWaitlist?.map((waitlist) => (
                          <div className="row justify-content-between">
                            <div className="d-content fs-3 col id-color text-start">
                              {waitlist?.name}
                            </div>
                            <div className="d-content fs-3 col id-color text-center">
                              {waitlist?.employeeName}
                            </div>
                            <div className="d-content fs-3 col id-color text-end">
                              {waitlist?.waitingTime} min
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                    <div className="d-deco"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default WaitList;
