import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const fetchAsyncGetHome = createAsyncThunk("home/getHome", async () => {
  try {
    const res = await axiosInstance.get("/barbershop/home");
    console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
});

// trending images
export const fetchAsyncTrendingImages = createAsyncThunk("home/getTrendingImages", async () => {
  try {
    const res = await axiosInstance.get("/barbershop/trending-images");
    // console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
});

const initialState = {
  home: [],
  loading: "idle",
  error: null,

  // trending images
  trendingImages: [],
  trendingImagesStatus: "idle",
  trendingImagesError: null
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncGetHome.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchAsyncGetHome.fulfilled, (state, action) => {
        state.home = action.payload;
        state.loading = "succeeded";
      })
      .addCase(fetchAsyncGetHome.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = "failed";
      })
      .addCase(fetchAsyncTrendingImages.pending, (state) => {
        state.trendingImagesStatus = "pending";
        state.trendingImagesStatus = null;
      })
      .addCase(fetchAsyncTrendingImages.fulfilled, (state, action) => {
        state.trendingImages = action.payload;
        state.trendingImagesStatus = "succeeded";
      })
      .addCase(fetchAsyncTrendingImages.rejected, (state, action) => {
        state.trendingImagesError = action.error.message;
        state.trendingImagesStatus = "failed";
      });
  },
});

export default homeSlice.reducer;