import React, { useEffect } from "react";
import Image from "./Image";
import Title from "./Title";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncEmployees } from "../Redux/features/AboutSlice";

const OurTeam = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncEmployees());
  }, [dispatch]);

  const employees = useSelector((state) => state.About.employees);
  const status = useSelector((state) => state.About.employeesStatus);

  return (
    <section aria-label="section" className="no-top">
      <div className="container">
        <div className="row justify-content-center  ">
          <Title name="Our Team" />
          {status === "loading" ? (
            <>
              <div
                style={{ height: "100vh" }}
                className="d-flex mt-5 justify-content-center"
              >
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              {employees?.slice(0, 4).map((employee) => (
                <>
                  <Image
                    key={employee.id}
                    title={employee.name}
                    img={employee.url}
                  />
                </>
              ))}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
