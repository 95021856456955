import { useEffect } from "react";
import { jarallax } from "jarallax";
import Footer from "../components/Footer";
import { TfiMenuAlt } from "react-icons/tfi";
import { RxCrossCircled } from "react-icons/rx";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Header from "../components/Header";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOwnWaitlist } from "../Redux/features/WaitlistSlice";
import Loading from "../components/Loading";

function WaitList() {
  const dispatch = useDispatch();
  const params = useParams().id;
  const status = useSelector((state) => state.Waitlist.status);

  useEffect(() => {
    dispatch(getOwnWaitlist(params));
  }, [dispatch, params]);
  const singleWaitlist = useSelector((state) => state.Waitlist.singleWaitlist);
  console.log("singleWaitlist", singleWaitlist);

  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);
  return (
    <>
      <Header></Header>
      <div id="wrapper">
        <div className="no-bottom no-top" id="content">
          <div id="top"></div>

          <section id="subheader" className="jarallax">
            <img src="/img/background/2.jpg" className="jarallax-img" alt="" />
            <div className="container">
              <div className="row">
                <div className="col-lg-6 pt-5 mt-5 offset-lg-3 text-center">
                  <h2>Confirmation</h2>
                  <div className="de-separator"></div>
                </div>
              </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
          </section>

          <section id="wait" className="jarallax">
            <div className="de-gradient-edge-top"></div>
            <img src="/img/background/1.jpg" className="jarallax-img" alt="" />
            <div className="container relative z1000">
              <div className="row  justify-content-center">
                <div className=" col-md-10 bg-dark col-lg-9 col-xxl-6 col-12 custom-border">
                  <div className="row">
                    <div className="col-12">
                      <p className="text-center mb-0">BarberShop</p>
                      <div className="text-center py-3">
                        <IoMdCheckmarkCircleOutline
                          className="display-1"
                          style={{ color: "var(--primary-color)" }}
                        />
                      </div>
                      <h2 className="text-center mb-0">Thanks for waiting!</h2>
                      <p className="text-center mb-0 fw-semibold">
                        Stay on this page to get notified when it's your turn.
                      </p>
                    </div>
                  </div>
                  {status === "loading" ? (
                    <Loading />
                  ) : (
                    <>
                      {" "}
                      <div className="row justify-content-center text-center mt-4">
                        <div className="col-6">
                          <p className="mb-0">Place in line</p>
                          <p>{singleWaitlist[0]?.serialNumber}</p>
                        </div>
                        <div className="col-6">
                          <p className="mb-0">
                            Estimate wait
                          </p>
                          <p>{singleWaitlist[0]?.waitingTime} min</p>
                        </div>
                        <hr
                          style={{ backgroundColor: "var(--primary-color)" }}
                        />
                      </div>
                      <div className="row pb-4 justify-content-center text-center">
                        {singleWaitlist?.map((item) => (
                          <>
                            <div className="col-lg-3 pb-3 col-6">
                              <p className="mb-0 ">Name</p>
                              <p className="mb-0 fw-bolder pb-1">
                                {" "}
                                {item?.name}
                              </p>
                            </div>
                            <div className="col-lg-3 pb-3  col-6">
                              <p className="mb-0 ">Phone</p>
                              <p className="mb-0 fw-bolder pb-1">
                                {item?.phone}
                              </p>
                            </div>
                            <div className="col-lg-3 pb-3  col-6">
                              <p className="mb-0 ">Staff</p>
                              <p className="mb-0 fw-bolder pb-1">
                                {item?.employeeName}
                              </p>
                            </div>
                            <div className="col-lg-3 pb-3  col-6">
                              <p className="mb-0 ">Services</p>
                              <p className="mb-0 fw-bolder">
                                {" "}
                                {item?.serviceName}
                              </p>
                            </div>
                          </>
                        ))}
                      </div>
                      <hr
                        className="my-4"
                        style={{ backgroundColor: "var(--primary-color)" }}
                      />
                      <div className="row text-center justify-content-center align-items-center">
                        <div className="col-5">
                          <Link
                            to="/waitlist"
                            className="my-3 fw-bolder text-white"
                          >
                            <TfiMenuAlt /> View Waitlist
                          </Link>
                        </div>
                        <div className="col-5">
                          <Link to="/" className="mb-0 fw-bolder text-white">
                            <RxCrossCircled /> Leave Waitlist
                          </Link>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default WaitList;
