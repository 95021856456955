import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import Swal from "sweetalert2";

// Post waitlist
export const createAsyncWaitlist = createAsyncThunk(
  "waitlist/createWaitlist",
  async ({ employeeId, serviceId, data, navigate }) => {
    try {
      const res = await axiosInstance.post(
        `/waitlists/employees/${employeeId}/services/${serviceId}`,
        data
      );
      Swal.fire({
        icon: "success",
        title: "Your appointment has been booked successfully",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: 'dark-text',
          content: 'dark-text'
        }
      });
      navigate(`/confirmation/${res.data[0].id}`);
      return res.data;
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        customClass: {
          title: 'dark-text',
          content: 'dark-text'
        }
      });
    }
  }
);

// Velify Phone Number

export const VerifyAsyncPhoneNumber = createAsyncThunk(
  "waitlist/verifyPhoneNumber",
  async ({ waitlistId, data }) => {
    console.log(waitlistId, data);
    try {
      const res = await axiosInstance.post(
        `/waitlists/verify/${waitlistId}`,
        data
      );
      console.log(res.data);
      Swal.fire({
        icon: "success",
        title: "Your Phone Number has been verified successfully",
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: 'dark-text',
          content: 'dark-text'
        }
      });
      return res.data;
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        customClass: {
          title: 'dark-text',
          content: 'dark-text'
        }
      });
    }
  }
);

// Get Todays Waitlist
export const getTodaysWaitlist = createAsyncThunk(
  "waitlist/getTodaysWaitlist",
  async () => {
    try {
      const res = await axiosInstance.get("/waitlists/today");
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);


// Get Own waitlist
export const getOwnWaitlist = createAsyncThunk(
  "waitlist/getOwnWaitlist",
  async (waitlistId) => {
    console.log(waitlistId);
    try {
      const res = await axiosInstance.get(`/waitlists/track/${waitlistId}`);
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);
// Get Service Employees
export const getServiceEmployees = createAsyncThunk(
  "waitlist/getServiceEmployees",
  async (serviceId) => {
    try {
      const res = await axiosInstance.get(`services/${serviceId}/employees`);
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState = {
  waitlist: [],
  todaysWaitlist: [],
  serviceEmployees: [],
  singleWaitlist: [],
  verifyPhone: {},
  status: "idle",
  error: null,
};

const waitlistSlice = createSlice({
  name: "waitlist",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Post Waitlist
      .addCase(createAsyncWaitlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createAsyncWaitlist.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.waitlist = action.payload;
      })
      .addCase(createAsyncWaitlist.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Verify Phone Number
      .addCase(VerifyAsyncPhoneNumber.pending, (state) => {
        state.status = "loading";
      })
      .addCase(VerifyAsyncPhoneNumber.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.verifyPhone = action.payload;
      })
      .addCase(VerifyAsyncPhoneNumber.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Get Todays Waitlist
      .addCase(getTodaysWaitlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTodaysWaitlist.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.todaysWaitlist = action.payload;
      })
      .addCase(getTodaysWaitlist.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // get Own Waitlist
      .addCase(getOwnWaitlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getOwnWaitlist.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.singleWaitlist = action.payload;
      })
      .addCase(getOwnWaitlist.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      // Get Service Employees
      .addCase(getServiceEmployees.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getServiceEmployees.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.serviceEmployees = action.payload;
      })
      .addCase(getServiceEmployees.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default waitlistSlice.reducer;
