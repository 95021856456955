import { configureStore } from "@reduxjs/toolkit";
import ContactSlice from "./features/ContactSlice";
import HomeSlice from "./features/HomeSlice";
import scheduleSlice from "./features/ScheduleSlice";
import AboutSlice from "./features/AboutSlice";
import commonSlice from "./features/CommonSlice";
import GallerySlice from "./features/GallerySlice";
import WaitlistSlice from "./features/WaitlistSlice";
import ReservationSlice from "./features/ReservationSlice";

const store = configureStore({
  reducer: {
    About: AboutSlice,
    Common: commonSlice,
    Contact: ContactSlice,
    Gallery: GallerySlice,
    Home: HomeSlice,
    Schedule: scheduleSlice,
    Waitlist: WaitlistSlice,
    Reservation: ReservationSlice,
  },
});

export default store;