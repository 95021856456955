import { useEffect } from "react";
import { jarallax } from "jarallax";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Prices from "../components/Prices";
import Image from "../components/Image";
import Schedule from "../components/Schedule";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncCategories, getAsyncServiceHeadingImage } from "../Redux/features/CommonSlice";

function ServicesPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncServiceHeadingImage());
    dispatch(getAsyncCategories());
  }, [dispatch]);

  const headingImage = useSelector((state) => state.Common.serviceHeadingImage);
  const categories = useSelector((state) => state.Common.categories);
  const status = (state) => state.Common.categoriesStatus;

  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);
  useEffect(() => {
    const element = document.getElementById("service-head");
    if (element) element.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <div id="wrapper">
      <Header />
      <div className="no-bottom no-top" id="service-head">
        <div id="top"></div>

        <section id="subheader" className="jarallax">
          {/* <img src="/img/background/2.jpg" className="jarallax-img" alt="" /> */}
          <img src={headingImage?.url} className="jarallax-img" alt="" />
          <div className="container mt-lg-0 mt-5 pt-5 pt-lg-0">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 pt-5 text-center">
                <h1>Services</h1>
                <div className="de-separator"></div>
              </div>
            </div>
          </div>
          <div className="de-gradient-edge-bottom"></div>
        </section>
        <Prices />
        <section aria-label="section" className="no-top">
          <div className="container">
            <div className="row">
              {status === "loading" ? (
                <>
                  <div
                    style={{ height: "100vh" }}
                    className="d-flex mt-5 justify-content-center"
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  {categories?.slice(0, 4).map((cat) => (
                    <Image key={cat.id} title={cat.name} img={cat.url} />
                  ))}
                </>
              )}
            </div>
          </div>
        </section>

        <Schedule />
      </div>
      <Footer />
    </div>
  );
}

export default ServicesPage;
