import { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getServiceEmployees } from "../../Redux/features/WaitlistSlice";
import Loading from "../Loading";

function Staff({ setGoSteps, onEmployeeSelect, selections, mode }) {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  console.log(selectedItem);

  const handleItemClick = (id) => {
    setSelectedItem(id);
  };

  const handleNextStep = () => {
    onEmployeeSelect(selectedItem);
    setGoSteps(3);
  };

  useEffect(() => {
    dispatch(getServiceEmployees(selections.serviceId));
  }, [dispatch, selections.serviceId]);

  const employees = useSelector((state) => state.Waitlist.serviceEmployees);
  console.log(employees);
  const status = useSelector((state) => state.Waitlist.status);

  return (
    <>
      <div className="row justify-content-center mx-1">
        <div className="col-md-10 col-lg-9 col-xxl-7 col-12  text-center custom-border waitlist-box">
          <div className="row">
            <div className="col-2 text-start">
              <AiOutlineArrowLeft
                style={{ background: "var(--primary-color)" }}
                onClick={() => setGoSteps(1)}
                className=" gj-cursor-pointer back-btn fs-1 p-1 rounded"
              ></AiOutlineArrowLeft>
            </div>
            <div className="col-8 text-center">
              <h2 className=" display-6 fw-bold ">Select Staff</h2>
            </div>
            <div className="col-2 text-start"></div>
          </div>
          <div className="row justify-content-center">
            <div className="col-11 overflow-auto mobile-height">
              <div className="row justify-content-center">
                {status === "loading" ? (
                  <Loading />
                ) : (
                  <>
                    {employees?.map((i) => (
                      <div
                        key={i.id}
                        className={`form-check category-border col-xl-3 col-lg-5 col-5 mx-2 my-2 px-0 ${
                          selectedItem === i.id ? "service-bg" : ""
                        }`}
                      >
                        <label className="row mx-0">
                          <div className="col-12 pt-3 px-0 text-center">
                            <img src={i.url} className="img-fluid" alt="Employee" />
                          </div>
                          <div className="col-12 mx-auto py-1 text-center">
                            <p className="fs-5 pb-2 fw-bold m-0 user-select-none d-inline-block">
                              {i.name}
                            </p>
                            {
                              mode === "waitlist" ? (
                                <p className="pb-2 m-0 user-select-none">
                                  {i?.waitingTime}
                                </p>
                              ) : null
                            }
                            <input
                              className="form-check-input float-end me-2 visually-hidden"
                              type="radio"
                              name="radioService"
                              id={i.id}
                              onClick={() => handleItemClick(i.id)}
                            />
                          </div>
                        </label>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-8 col-lg-6 mx-auto">
            <button
              onClick={handleNextStep}
              className="btn-main bg-primary mx-auto mt-5 w-100 px-0"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Staff;
