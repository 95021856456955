import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAsyncTrendingImages } from "../Redux/features/HomeSlice";

const Trending = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncTrendingImages());
  }, [dispatch]);

  const allTrendingImage = useSelector((state) => state.Home.trendingImages);
  const status = useSelector((state) => state.Home.trendingImagesStatus);

  return (
    <section className="px-5 py-5">
      <div className="container">
        <div className="row d-flex mb-5 justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-11 col-sm-11">
            <div className="section-tittle text-center mb-90 my-5">
              <span>Trending</span>
              <h2>Trending Style</h2>
            </div>
            <div className="mb-5">
              <span className="d-block header-line"></span>
              <span className="d-block header-line2"></span>
            </div>
          </div>
        </div>
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={15}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        breakpoints={{
          1281: {
            slidesPerView: 4,
          },
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <div className="justify-content-center">
          {status === "loading" ? (
            <>
              <div
                style={{ height: "100vh" }}
                className="d-flex mt-5 justify-content-center"
              >
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              {allTrendingImage?.map((img, index) => (
                <SwiperSlide className="mb-5 position-relative" key={img.id}>
                  <div className="c-item mb-5">
                    <span className="c-item_info">
                      <span className="c-item_title">{img.title}</span>
                      <span className="c-item_wm">#{index + 1}</span>
                    </span>
                    <div className="c-item_wrap">
                      <img src={img.url} className="lazy img-fluid" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </>
          )}
        </div>
      </Swiper>
    </section>
  );
};

export default Trending;
