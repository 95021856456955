import React, { useEffect } from "react";
import { jarallax } from "jarallax";
import Footer from "../components/Footer";
import DateAndTime from "../components/WaitList/DateAndTime";
function AddForWaitList() {
  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);
  return (
    <>
      <div id="wrapper">
        <div className="no-bottom no-top" id="content">
          <div id="top"></div>

          <section id="subheader" className="jarallax">
            <img src="/img/background/2.jpg" className="jarallax-img" alt="" />
            <div className="container">
              <div className="row">
                <div className="col-lg-6 offset-lg-3 text-center">
                  <h1>join waitlist</h1>
                  <div className="de-separator"></div>
                </div>
              </div>
            </div>
            <div className="de-gradient-edge-bottom"></div>
          </section>

          <DateAndTime />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default AddForWaitList;
