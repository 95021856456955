import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAsyncFavoriteImages } from "../Redux/features/GallerySlice";

const Gallery = () => {


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAsyncFavoriteImages());
  }, [dispatch]);
  const favoriteImages = useSelector((state) => state.Gallery.favoriteImage);

  return (
    <section>
      <div>
        <div className="row">
          <div className="col-lg-8 offset-lg-2 text-center">
            <h2 className="">Gallery</h2>
            <div className="de-separator"></div>
            <div className="spacer-single"></div>
          </div>
        </div>
        <div className="pb-3 mx-3">
          <div id="gallery" className="row g-3 justify-content-center">
            {favoriteImages?.slice(0, 4).map((image, index) => (
              <div key={index} className="col-md-6 col-lg-3 col-12 item">
                <div className="de-image-hover rounded">
                  <span className="dih-overlay"></span>
                  <img src={image.url} className="lazy img-fluid gallery-img" alt="" />
                </div>
              </div>
            ))}
            <div className="text-center mt-4">
              <Link to="/gallery">
                <a href="service-head" className="btn-main bg-primary">
                  See All Photos
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
