import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAsyncCategories } from "../Redux/features/CommonSlice";

function Prices() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncCategories());
  }, [dispatch]);
  const categories = useSelector((state) => state.Common.categories);

  return (
    <>
      <section id="section-pricing" aria-label="section">
        <div className="container">
          <div className="row g-5" id="gallery">
            {categories
              .filter(
                (category) => category.services && category.services.length > 0
              )
              .map((category) => (
                <div key={category.id} className="col-lg-6 item">
                  <div className="sc-wrap">
                    <h3>{category.name}</h3>
                    <div className="def-list-dots">
                      {category.services.map((service) => (
                        <dl key={service.id}>
                          <dt>
                            <span>{service.name}</span>
                          </dt>
                          <dd>${service.waitlistPriceInDollar}</dd>
                        </dl>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Prices;