import React from 'react'

function Title(props) {
    return (
        <>
            <div className="col-lg-12 text-center">
                <h2 className="">{props.name}</h2>
                <div className="de-separator"></div>
            </div>
        </>
    )
}

export default Title