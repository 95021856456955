import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

export const getAsyncContact = createAsyncThunk("contact/getContact", async () => {
  try {
    const res = await axiosInstance.get("/barbershop/contact");
    // console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error.message);
  }
});

const initialState = {
  contacts: {},
  status: "idle",
  error: null,
};

const ContactSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAsyncContact.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAsyncContact.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contacts = action.payload;
      })
      .addCase(getAsyncContact.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default ContactSlice.reducer;