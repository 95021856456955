import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './router/Router';
import { RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './Redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <RouterProvider router={Router}></RouterProvider>
</Provider>
);

