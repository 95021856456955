import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAsyncCategories } from "../Redux/features/CommonSlice";

const Services = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAsyncCategories());
  }, [dispatch]);
  const categories = useSelector((state) => state.Common.categories);
  console.log(categories)
  const status = useSelector((state) => state.Common.categoriesStatus);

  return (
    <section className="jarallax no-top">
      <div className="de-gradient-edge-top"></div>
      <img src="/img/background/1.jpg" className="jarallax-img" alt="" />
      <div className="container relative z1000">
        <div className="row justify-content-center">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 text-center">
              <h2 className="">Services</h2>
              <div className="de-separator"></div>
              <div className="spacer-single"></div>
            </div>
          </div>
          {status === "loading" ? (
            <>
              <div
                style={{ height: "100vh" }}
                className="d-flex mt-5 justify-content-center"
              >
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          ) : (
            <>
              {" "}
              {categories?.slice(0, 4).map((category) => (
                <div
                  key={category.id}
                  className="col-lg-3 col-md-6 text-center"
                  data-jarallax-element="-20"
                >
                  <div className="de-box-a">
                    <div className="d-image">
                      <img src={category.url} alt="" />
                    </div>
                    <div className="d-deco-1"></div>
                    <div className="d-deco-2"></div>
                  </div>
                  <div className="spacer-20"></div>
                  <h4>{category.name}</h4>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="spacer-single"></div>
        <div className="text-center">
          <Link to="/services">
            <a href="service-head" className="btn-main bg-primary">
              All Services &amp; Prices
            </a>
          </Link>
        </div>
      </div>
      <div className="de-gradient-edge-bottom"></div>
    </section>
  );
};

export default Services;
