import React from 'react'

function Image(props) {
    return (
        <>
            <div className="col-lg-3 col-md-6 text-center" data-jarallax-element="-20">
                <div className="de-box-a">
                    <div className="d-image">
                        <img src={props.img} alt="" />
                    </div>
                    <div className="d-deco-1"></div>
                    <div className="d-deco-2"></div>
                </div>
                <div className="spacer-20"></div>
                <h4>{props.title}</h4>
            </div>
        </>
    )
}

export default Image