import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";

// social links
export const getAsyncSocialLinks = createAsyncThunk(
  "socialLinks/getAsyncSocialLinks",
  async () => {
    try {
      const res = await axiosInstance.get("/barbershop/socialLinks");
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

// schedule
export const getAsyncSchedule = createAsyncThunk(
  "schedule/getAsyncSchedule",
  async () => {
    try {
      const res = await axiosInstance.get("/barbershop/schedule");

      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

// categories
export const getAsyncCategories = createAsyncThunk(
  "categories/getAsyncCategories",
  async () => {
    try {
      const res = await axiosInstance.get("/barbershop/services");

      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

// service heading image
export const getAsyncServiceHeadingImage = createAsyncThunk(
  "categories/getAsyncServiceHeadingImage",
  async () => {
    try {
      const res = await axiosInstance.get("/barbershop/service-header-image");

      return res.data;
    } catch (err) {
      console.log(err);
    }
  }
);

// initial states
const initialState = {
  // Social links
  socialLinks: {},
  socialLinkStatus: "idle",
  socialError: null,
  // Category data
  categories: [],
  categoriesStatus: "idle",
  categoriesError: null,
  // Schedule data
  schedule: {},
  scheduleStatus: "idle",
  scheduleError: null,

  // service heading image
  serviceHeadingImage: {},
  serviceHeadingImageStatus: "idle",
  serviceHeadingImageError: null,
};

// slice for common data
const commonSlice = createSlice({
  name: "commonData",
  initialState,
  extraReducers: (builder) => {
    builder
      // social links
      .addCase(getAsyncSocialLinks.pending, (state) => {
        state.socialLinkStatus = "loading";
      })
      .addCase(getAsyncSocialLinks.fulfilled, (state, action) => {
        state.socialLinkStatus = "succeeded";
        state.socialLinks = action.payload;
      })
      .addCase(getAsyncSocialLinks.rejected, (state, action) => {
        state.socialLinkStatus = "failed";
        state.socialError = action.socialError.message;
      })
      // Categories
      .addCase(getAsyncCategories.pending, (state) => {
        state.categoriesStatus = "loading";
      })
      .addCase(getAsyncCategories.fulfilled, (state, action) => {
        state.categoriesStatus = "succeeded";
        state.categories = action.payload;
      })
      .addCase(getAsyncCategories.rejected, (state, action) => {
        state.categoriesStatus = "failed";
        state.categoriesError = action.categoriesError.message;
      })
      // Schedule
      .addCase(getAsyncSchedule.pending, (state) => {
        state.scheduleStatus = "loading";
      })
      .addCase(getAsyncSchedule.fulfilled, (state, action) => {
        state.scheduleStatus = "succeeded";
        state.schedule = action.payload;
      })
      .addCase(getAsyncSchedule.rejected, (state, action) => {
        state.scheduleStatus = "failed";
        state.scheduleError = action.scheduleError.message;
      })
      // service heading image
      .addCase(getAsyncServiceHeadingImage.pending, (state) => {
        state.serviceHeadingImageStatus = "loading";
      })
      .addCase(getAsyncServiceHeadingImage.fulfilled, (state, action) => {
        state.serviceHeadingImageStatus = "succeeded";
        state.serviceHeadingImage = action.payload;
      })
      .addCase(getAsyncServiceHeadingImage.rejected, (state, action) => {
        state.serviceHeadingImageStatus = "failed";
        state.serviceHeadingImageError =
          action.serviceHeadingImageError.message;
      });
  },
});

export default commonSlice.reducer;
