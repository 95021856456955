import { FaFacebookF, FaInstagram, FaTiktok } from "react-icons/fa";
import { useSelector } from "react-redux";

function Footer() {
  const socialLink = useSelector((state) => state.Common.socialLinks);

  return (
    <footer className="footer-bg">
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-4 text-center">
            <div className="social-icons big">
              <a
                href={socialLink?.facebook}
                target="_blank"
                rel="noreferrer"
                className="social-box py-2"
              >
                <FaFacebookF />
              </a>
              <a
                href={socialLink?.instagram}
                target="_blank"
                rel="noreferrer"
                className="social-box py-2"
              >
                <FaInstagram />
              </a>
              <a
                href={socialLink?.tiktok}
                target="_blank"
                rel="noreferrer"
                className="social-box py-2"
              >
                <FaTiktok />
              </a>
            </div>
          </div>
          <div className="col-lg-4 text-lg-center text-center">
            <img src="/img/ABSS.webp" style={{ height: "70px" }} alt="" />
          </div>
          <div className="col-lg-4 text-lg-end text-center pt-2">
            Copyright 2023 -{" "}
            <a
              style={{ color: "var(--primary-color)" }}
              href="https://abss.tech"
              target="blank"
            >
              {" "}
              ABSS
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;